import React from 'react'
import { EllicotLayout } from 'components/EllicotLayout'
import { ContactForm } from 'components/ContactForm'
import { ContactHero } from 'components/ContactHero'
import { ContactSocials } from 'components/ContactSocials'
import { PrefClientPortal } from 'components/PrefClientPortal'
import { Contact } from 'components/Contact'
import { navigate } from '@reach/router'
import { Helmet } from "react-helmet"

const ContactPage: React.FunctionComponent = () => {
  return (
    <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" />
      <title>Ellicott Realty</title>
    </Helmet>
    <EllicotLayout>
      
      <div className="contactPage">
        <ContactForm />
        {/* <ContactSocials /> */}
        {/* <PrefClientPortal /> */}
        {/* <Contact /> */}
      </div>
      </EllicotLayout>
      </div>
  )
}

export default ContactPage
