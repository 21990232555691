import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FlexDiv } from '../design-system/FlexDiv'
import { Input } from '../design-system/Input'
import { GoogleMap } from '../components/GoogleMap'
import { ContactFormDataType } from '@services/lifted/send-contact-form.service'
import { services } from '@services'
import NumberFormat from 'react-number-format'
import Select, { components } from 'react-select'
import 'aos/dist/aos.css'
import { toast, ToastContainer, useToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        class="dropdown-heading-dropdown-arrow gray"
      >
        <path d="M6 9L12 15 18 9"></path>
      </svg>
    </components.DropdownIndicator>
  )
}

const interestedOptions = [
  {
    value: 'I need help buying a property',
    label: 'I need help buying a property',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'I need help selling my property',
    label: 'I need help selling my property',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'New Home Sales',
    label: 'New Home Sales',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Commercial',
    label: 'Commercial',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Industrial',
    label: 'Industrial',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Land Development',
    label: 'Land Development',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Investments',
    label: 'Investments',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Other / Not Listed',
    label: 'Other / Not Listed',
    name: 'interestedProperties',
    type: 'text',
  },
]

const ContactP = styled.p`
  font-family: 'Montserrat';
  font-size: 0.9em;
  color: #000;
`

const Label = styled.span`
  font-family: 'Montserrat';
  font-size: 1em;
  font-stretch: normal;
  font-style: normal;
  color: white;
  -webkit-font-smoothing: antialiased;
`

const RadioLabel = styled.label<{ for: any }>`
  font-family: 'Montserrat';
  margin: 0 0.5em;
  transform: translateY(-0.15em);
  color: black;
  -webkit-font-smoothing: antialiased;
  display: inline-flex;
`

const ButtonSubscribe = styled.button`
  background: #000;
  color: #fff;
  border: none;
  width: 35%;
  cursor: pointer;
  margintop: 20px;
  font-family: 'Montserrat';
  letter-spacing: 1.5px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
`

export const ContactForm: React.FunctionComponent = () => {
  let AOS
  useEffect(() => {
    /**
     * Server-side rendering does not provide the 'document' object
     * therefore this import is required either in useEffect or componentDidMount as they
     * are exclusively executed on a client
     */
    const AOS = require('aos')
    AOS.init({
      once: true,
    })
  }, [])

  useEffect(() => {
    console.log(formData)
  }, [formData])

  useEffect(() => {
    if (AOS) {
      AOS.refresh()
    }
  })
  const location = {
    latitude: '43.875700',
    longitude: '-79.438630',
    text: 'My Marker',
  }

  const [formData, setFormData] = useState<ContactFormDataType>({
    fname: '',
    lname: '',
    email: '',
    phone: '',
    interestedProperties: '',
    underContract: 'no',
  })
  const [loading, setLoading] = useState(false)
  const handleSelectChange = (e: any) => {
    setFormData({
      ...formData,
      interestedProperties: e.value,
    })
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target) return
    const name = e.target.name
    const value = e.target.value

    const newForm = {
      ...formData,
      [name]: value,
    }

    setFormData(newForm)
  }
  const submitForm = async () => {
    if (loading) {
      return
    }
    setLoading(true)
    try {
      const res = await fetch('/.netlify/functions/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
      if (res.ok) {
        toast.success('Received. Elicott will send you a response shortly')
      }
      setLoading(false)
    } catch (err) {
      console.log(err)
      setLoading(false)
    }
  }

  return (
    <div style={{ paddingTop: 0 }}>
      <ToastContainer />
      <FlexDiv
        style={{
          justifyContent: 'center',
          flex: '1.5',
          padding: '0',
          height: '400px',
        }}
        className="mobileColumn gMapsContainer"
      >
        <GoogleMap
          zoom={11}
          markers={[
            {
              location: new google.maps.LatLng(
                parseFloat(location.latitude),
                parseFloat(location.longitude),
              ),
            },
          ]}
          center={
            new google.maps.LatLng(
              parseFloat(location.latitude),
              parseFloat(location.longitude),
            )
          }
        />
      </FlexDiv>
      <FlexDiv
        style={{
          color: 'white',
          maxWidth: '83%',
          margin: '0em auto ',
        }}
        justify="center"
        className="contactFormWrapper"
        data-aos="fade-up"
        data-aos-delay="50"
        data-aos-duration="2000"
      >
        <FlexDiv
          vert
          justify="center"
          style={{ flex: '2', maxWidth: 'auto' }}
          className="letsChat"
        >
          <FlexDiv
            style={{
              margin: '0 0.5em',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <h1 className="contactTitle"> Let's Chat</h1>
            <ContactP>
              Whether you're looking to purchase a home, a second property, or
              would like to sell, we would love to work with you. Leave your
              details below and a representative from Ellicott Realty will get
              back to you.
            </ContactP>
          </FlexDiv>
          <div style={{ display: 'flex' }} className="letsChatWrapper">
            <div
              style={{
                display: 'flex',
                width: '27.5%',
                flexDirection: 'column',
                margin: '0 7.5px',
              }}
              className="ellicottOfficeInfo"
            >
              <div>
                <h1
                  style={{
                    color: '#000',
                    marginBottom: '5px',
                    fontFamily: 'Montserrat-Bold',
                    fontSize: '20px',
                    textTransform: 'uppercase',
                  }}
                >
                  {' '}
                  Phone Number
                </h1>
                <p style={{ color: '#000', marginTop: '0', fontSize: '13px' }}>
                  <a
                    href="tel:416-203-7447"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {' '}
                    (416) 203-7447
                  </a>
                </p>
              </div>
              <div>
                <h1
                  style={{
                    color: '#000',
                    marginBottom: '5px',
                    fontFamily: 'Montserrat-Bold',
                    fontSize: '20px',
                    textTransform: 'uppercase',
                  }}
                >
                  {' '}
                  Email Address
                </h1>
                <p style={{ color: '#000', marginTop: '0', fontSize: '13px' }}>
                  <a
                    href="mailto:hello@ellicottrealty.ca"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {' '}
                    hello@ellicottrealty.ca
                  </a>
                </p>
              </div>
              <div>
                <h1
                  style={{
                    color: '#000',
                    marginBottom: '5px',
                    fontFamily: 'Montserrat-Bold',
                    fontSize: '20px',
                    textTransform: 'uppercase',
                  }}
                >
                  {' '}
                  Address
                </h1>
                <p style={{ color: '#000', marginTop: '0', fontSize: '13px' }}>
                  <a
                    href="https://goo.gl/maps/8CTbvx5GY2srXujU7"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {' '}
                    10184 Yonge Street, <br></br>
                    Richmond Hill{' '}
                  </a>
                </p>
              </div>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', width: '70%' }}
              className="contactFormOutterWrapper"
            >
              <FlexDiv
                style={{ marginTop: 10, flexWrap: 'nowrap' }}
                className="noWrapContact"
              >
                <Input
                  label="First Name"
                  name="fname"
                  className="contactFormInput"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                  }}
                  onChange={handleChange}
                />
                <Input
                  label="Last Name"
                  name="lname"
                  className="contactFormInput"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                  }}
                  onChange={handleChange}
                />
              </FlexDiv>
              <FlexDiv
                style={{ marginTop: 10 }}
                className="contactEmailPhoneWrapper"
              >
                <Input
                  name="email"
                  label="Email Address"
                  className="contactFormInput"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                  }}
                  onChange={handleChange}
                />
                <NumberFormat
                  name="phone"
                  placeholder={'Enter phone number'}
                  format="(###) ###-####"
                  label="Phone Number"
                  className="contactFormInput"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                    height: '40px',
                    paddingLeft: '15px',
                  }}
                  onChange={handleChange}
                />
              </FlexDiv>
              <FlexDiv
                style={{ marginTop: 10 }}
                className="mobileSelectContactFormWrapper"
              >
                <Select
                  options={interestedOptions}
                  name="interestedProperties"
                  placeholder="I'm Interested In:"
                  onChange={handleSelectChange}
                  className="signUpDropDownWidth hundoWidth"
                  classNamePrefix="filter"
                  components={{ DropdownIndicator }}
                  style={{ color: '#000' }}
                />
              </FlexDiv>
              <div>
                <FlexDiv>
                  <Label
                    style={{
                      margin: '7.5px',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '13.5px',
                    }}
                  >
                    Are you currently under a contract with a REALTOR?
                  </Label>
                </FlexDiv>
                <div
                  className="contactInputWrapper"
                  onChange={handleChange}
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '7.5px',
                  }}
                >
                  <div>
                    <input
                      type="radio"
                      id="yes"
                      name="underContract"
                      checked={formData.underContract === 'yes'}
                      value={'yes'}
                    />
                    <RadioLabel for="yes" style={{ fontSize: '13.5px' }}>
                      Yes
                    </RadioLabel>
                  </div>
                  <div
                    style={{ marginTop: '' }}
                    className="radioLabelRightSide"
                  >
                    <input
                      type="radio"
                      id="no"
                      name="underContract"
                      checked={formData.underContract === 'no'}
                      value={'no'}
                      style={{ marginTop: 5 }}
                    />
                    <RadioLabel for="no" style={{ fontSize: '13.5px' }}>
                      No
                    </RadioLabel>
                  </div>
                </div>
              </div>
              <FlexDiv style={{ marginTop: 10 }}>
                <Input
                  name="additionalDetails"
                  label="Question / Message:"
                  lines={5}
                  onChange={handleChange}
                  className="myInput contactFormInput"
                  style={{
                    marginRight: 5,
                    background: '#fff',
                    color: '#000',
                    border: '1px solid lightgray',
                  }}
                />
              </FlexDiv>
              <FlexDiv vert style={{ margin: '0 0.5em' }}>
                <FlexDiv vert>
                  <FlexDiv>
                    {!loading ? (
                      <ButtonSubscribe
                        onClick={submitForm}
                        style={{
                          marginTop: 30,
                          fontFamily: 'Montserrat-Bold',
                          letterSpacing: '0px',
                          padding: '10px',
                          fontSize: '1.1em',
                        }}
                      >
                        NEXT
                      </ButtonSubscribe>
                    ) : (
                      <p style={{ color: 'black' }}>Processing request..</p>
                    )}
                  </FlexDiv>
                </FlexDiv>
              </FlexDiv>
            </div>
          </div>
        </FlexDiv>

        {/* <FlexDiv style={{ flex: '0.5' }}>
          <FlexDiv
            style={{ flexFlow: 'column', placeSelf: 'center' }}
            className="officeDetailsWrapper"
          >
            <div>
              <OfficeDetailTitle>Richmond Hill Office</OfficeDetailTitle>
              <OfficeDetailText>10184 Yonge Street,</OfficeDetailText>
              <OfficeDetailText>Richmond Hill, Ontario,</OfficeDetailText>
              <OfficeDetailText>L4C 1T6</OfficeDetailText>
            </div>
            <div>
              <OfficeDetailTitle>Phone Number</OfficeDetailTitle>
              <OfficeDetailText>(416) 203-7447 (Office)</OfficeDetailText>
            </div>
            <div>
              <OfficeDetailTitle>The Ellicott Team</OfficeDetailTitle>
              <OfficeDetailText>
                Meet our Realtors<RealtorWrapper>®</RealtorWrapper>
              </OfficeDetailText>
            </div>
          </FlexDiv>
        </FlexDiv> */}
      </FlexDiv>
    </div>
  )
}
